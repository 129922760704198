/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from 'moment-timezone';
import sortFunc from '../../../utils/ordernarColunaInt';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';

const ExportCSV = (props) => {
  const headers = [
    { label: 'Loja', key: 'rede' },
    ...(props.columns || [])
      .filter(col => col.dataField !== 'rede')
      .map(col => ({
        label: col.text,
        key: col.dataField
      }))
  ];

  const formattedData = (props.data || []).map(row => {
    const newRow = { ...row };
    headers.forEach(header => {
      if (header.key !== 'rede') {
        const valor = Number(row[header.key] || 0);
        newRow[header.key] = valor.toFixed(2).replace('.', ',');
      }
    });
    return newRow;
  });

  return (
    <div>
      <CSVLink
        data={formattedData}
        headers={headers}
        separator={';'}
        filename={`Historico${moment().format('YYYYMMDDHHmmss')}.csv`}
      >
        <button className="btn-primario-slim ml-2">
          <i className="fa fa-file-download px-2"></i>
        </button>
      </CSVLink>
    </div>
  );
};

const { SearchBar } = Search;

const defaultColumns = [
  {
    dataField: 'rede',
    text: 'Loja',
    sort: true,
  }
];

export default function Historico() {
  const [data, setData] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState('');
  const [redesSelect, setRedesSelect] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [dataInicial, setDataInicial] = useState(moment().startOf('year').format('YYYY-MM-DD'));
  const [dataFinal, setDataFinal] = useState(moment().endOf('year').format('YYYY-MM-DD'));
  const [columns, setColumns] = useState([
    {
      dataField: 'rede',
      text: 'Loja',
      sort: true,
    }
  ]);

  useEffect(() => {
    buscarHistorico();
  }, []);

  const buscarHistorico = async () => {
    setBuscando(true);
    try {
      const resultado = await api.post('/api/historico/integrar-smart', {
        dataInicial: moment(dataInicial).format('YYYY-MM-DD'),
        dataFinal: moment(dataFinal).format('YYYY-MM-DD')
      });
      
      if (resultado?.data?.historico) {
        const novasColunas = [
          {
            dataField: 'rede',
            text: 'Loja',
            sort: true,
          },
          ...(resultado.data.periodos || []).map(periodo => ({
            dataField: periodo,
            text: periodo,
            sort: true,
            formatter: (cell) => Number(cell).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
          })),
          {
            dataField: 'total',
            text: 'Total',
            sort: true,
            formatter: (cell) => Number(cell).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
          }
        ];

        const dadosComTotal = resultado.data.historico.map(item => {
          const total = resultado.data.periodos.reduce((acc, periodo) => acc + (Number(item[periodo]) || 0), 0);
          return { ...item, total };
        });

        setColumns(novasColunas);
        setData(dadosComTotal);
      } else {
        setData([]);
      }
    } catch (error) {
      toast.error('Erro ao buscar dados', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setData([]);
    } finally {
      setBuscando(false);
    }
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: 'Todos', value: data.length },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Histórico</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="mb-4">
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Loja</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            value={redeSelecionada}
            onChange={(e) => setRedeSelecionada(e.target.value)}
          >
            <option value="">Todas</option>
            {data.map((item) => (
              <option value={item.rede} key={item.rede}>
                {item.rede}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="dataInicial">Data Inicial</label>
          <input
            type="date"
            className="input-select mb-3"
            name="dataInicial"
            id="dataInicial"
            value={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
          />
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="dataFinal">Data Final</label>
          <input
            type="date"
            className="input-select mb-3"
            name="dataFinal"
            id="dataFinal"
            value={dataFinal}
            onChange={(e) => setDataFinal(e.target.value)}
          />
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-end mb-3"
        >
          <button className="btn-busca" type="submit" onClick={buscarHistorico}>
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="rede"
          data={data.filter(item => !redeSelecionada || item.rede === redeSelecionada)}
          columns={columns}
          search
          exportCSV
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <ExportCSV {...props.csvProps} data={data} columns={columns.slice(1)} />
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </Container>
  );
}
